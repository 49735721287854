//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TuoShiLOGO from "../../components/TuoShiLOGO.vue";
export default {
  components: { TuoShiLOGO },
  data () {
    return {
      clickEnent: "",
    };
  },
  created () {
    this.clickEnent = this.$route.query.clickEnent;
  },
  methods: {
    FunClick (res) {
      console.log(res);
      if (res == "充值") {
        this.$router.replace({ path: "/Pay" }).catch((err) => {
          console.log(err);
        });
      }
      if (res == "通讯录") {
        this.$router.replace({ path: "/LinkM" }).catch((err) => {
          console.log(err);
        });
      }
      if (res == "成绩单") {
        this.$router.replace({ path: "/Grade" }).catch((err) => {
          console.log(err);
        });
      }
      if (res == "考勤") {
        this.$router.replace({ path: "/Attendance" }).catch((err) => {
          console.log(err);
        });
      }
      if (res == "轨迹") {
        this.$router.replace({ path: "/Track" }).catch((err) => {
          console.log(err);
        });
      }
      if (res == "课程表") {
        this.$router.replace({ path: "/ClassSchedule" }).catch((err) => {
          console.log(err);
        });
      }
      if (res == "消费限制") {
        this.$router.replace({ path: "/Astrict" }).catch((err) => {
          console.log(err);
        });
      }
      if (res == "费用明细") {
        this.$router.replace({ path: "/Money" }).catch((err) => {
          console.log(err);
        });
      }
      if (res == "充值") {
        this.$router.replace({ path: "/Mney" }).catch((err) => {
          console.log(err);
        });
      }
    },
  },
};
